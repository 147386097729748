import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import ReactNotification from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';

import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { demoPages, layoutMenu } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import { useIdleTimer } from 'react-idle-timer'
import {DOMAIN, MENSAJE_ERROR, TIMEOUT_IDLE} from "../config";
import {_getAuthHeaders, _loader, _notify, _removeLoader, _undefined} from "../helpers";
import axios from "axios";

const App = () => {
	getOS();


	const cerrarSesion =()=>{
		let obj = localStorage.getItem('_dataUser');
		if (!_undefined(obj)) {
			_loader()
			axios.post(DOMAIN + '/auth/logout', {}, _getAuthHeaders()).then(response => {
				console.log(response)
				response = response.data
				if (!response.error) {
					_notify(response.mensaje, 'success')
					localStorage.clear()
					location.href = '/login'
				} else {
					_notify(response.mensaje, 'danger')
				}
				_removeLoader()
			}).catch(error => {
				_removeLoader()
				_notify(MENSAJE_ERROR, 'danger')
			});
		}
	}

	const onIdle = () => {
		// Close Modal Prompt
		// Do some idle action like log out your user
		console.log("inactivo")
		cerrarSesion()
	}
	const onActive = (event) => {
		// Close Modal Prompt
		// Do some active action
		console.log("activo")

	}
	const idleTimer = useIdleTimer({timeout: TIMEOUT_IDLE, onIdle, onActive })

	if (idleTimer) {
		console.log("idle!",idleTimer);
	}

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		// idleTimer.start()
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, layoutMenu.blank.path];

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<TourProvider
					steps={steps}
					styles={styles}
					showNavigation={false}
					showBadge={false}>
					<div
						ref={ref}
						className='app'
						style={{
							backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
							zIndex: fullScreenStatus && 1,
							overflow: fullScreenStatus && 'scroll',
						}}>
						<Routes>
							{withOutAsidePages.map((path) => (
								<Route key={path} path={path} />
							))}
							<Route path='*' element={<Aside />} />
						</Routes>
						<Wrapper />
					</div>
					<Portal id='portal-notification'>
						<ReactNotification />
					</Portal>
				</TourProvider>
			</ToastProvider>
		</ThemeProvider>
	);
};

export default App;
