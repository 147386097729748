const origin  = window.location.origin;
let domain = "https://api.xrprompterdemo.develop.auratech.com.mx";
let timeoutIdle = 1800000  // 5 minutos

if(origin == "https://xrprompterdemo.develop.auratech.com.mx"){
    domain = "https://api.xrprompterdemo.develop.auratech.com.mx"
    // timeoutIdle = 800000
    // timeoutIdle = 300000
    timeoutIdle = 9999000000
}
if(origin == "https://xrprompterdemo.staging.auratech.com.mx"){
    domain = "https://api.xrprompterdemo.staging.auratech.com.mx"
}
if(origin == "https://xrprompterdemo.auratech.com.mx"){
    domain = "https://api.xrprompterdemo.auratech.com.mx"
}
if(origin == "http://localhost:3000"){
    timeoutIdle = 9999000000
    // timeoutIdle = 300000
    // timeoutIdle = 800000
}

export const TIMEOUT_IDLE = timeoutIdle
export const DOMAIN = domain + "/api"
export const URL_LOGIN_AZURE = domain + "/login/azure"
export const MENSAJE_ERROR = 'Ha ocurrido un error inesperado. Intenta más tarde.'







